
import { mapState } from 'vuex'

export default {
  name: 'HomePartners',

  data () {
    return {
      partners: [
        /*{
          img: 'https://image.escrime-occasion.com/assets/partners/CenturionEscrime.png',
          name: 'Centurion Escrime',
          website: 'https://www.centurion-escrime.com/',
          height: '100px'
        },*/
        {
          img: 'https://image.escrime-occasion.com/assets/partners/logo-FFE-2023.png',
          name: 'Fédération Française d\'escrime',
          website: 'https://www.escrime-ffe.fr/',
          height: '60px',
          countries: ['FR', 'BE', 'CH', 'LU']
        },
        {
          img: 'https://image.escrime-occasion.com/assets/partners/stagescrime_logo_original.png',
          name: 'Stage Escrime',
          website: 'https://www.stagescrime.com',
          height: '80px',
          countries: ['FR', 'BE', 'CH', 'LU']
        },
      ]
    }
  },
  computed: {
    ...mapState('layout', ['currentCountry']),
    countryPartners () {
      return this.partners.filter(p => p.countries.includes(this.currentCountry))
    }
  }
}
