
import { API } from '@/services/api.service'
import HomePartners from '@/components/home/HomePartners'
import HomeOffers from '@/components/home/HomeOffers'
import HomeBrands from '@/components/home/HomeBrands'
import BannerView from '@/components/banner/BannerView'
import BANNER_TYPE from '@/enum/BannerType'
import { getRootPath } from '../utils'
import { mapState } from 'vuex'

export default {
  components: { BannerView, HomeOffers, HomePartners, HomeBrands },
  name: 'index',
  head () {
    const title = this.$t('pages.home.seo.metaTitle')
    const description = this.$t('pages.home.seo.metaDescription')
    return {
      title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'twitter:title', name: 'twitter:title', content: title },

        { hid: 'description', name: 'description', content: description },
        { hid: 'og:description', property: 'og:description', content: description },
        { hid: 'twitter:description', name: 'twitter:description', content: description },
      ]
    }
  },
  jsonld () {
    return [
      {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        'url': getRootPath(this.$i18n.locale),
        'potentialAction': {
          '@type': 'SearchAction',
          'target': this.$config.baseUrl + this.localePath({ name: 'search' }) + '?q={search_term_string}&f=google',
          'query-input': 'required name=search_term_string'
        }
      }
    ]
  },

  computed: {
    ...mapState('auth', ['isAuthenticated']),
    ...mapState('layout', ['currentCountry']),
  },

  data () {
    return {
      search: '',
      posts: undefined
    }
  },

  created () {
    this.BANNER_TYPE = BANNER_TYPE
  },

  fetch () {
    // this.retrievePosts()
  },

  methods: {
    searchOffer () {
      const q = this.search.trim()
      if (q === '') return
      this.$router.push(this.localePath({ name: 'search', query: { q, page: 1 } }))
      this.$store.dispatch('gtm/search', q)
    },

    async retrievePosts () {
      try {
        const response = await API.get('/posts')
        this.posts = response.data
      } catch (e) {
        this.posts = undefined
      }
    }
  }
}
