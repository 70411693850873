import { render, staticRenderFns } from "./index.vue?vue&type=template&id=73cb21d5&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=73cb21d5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73cb21d5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeBrands: require('/app/components/home/HomeBrands.vue').default,HomeOffers: require('/app/components/home/HomeOffers.vue').default,HomePartners: require('/app/components/home/HomePartners.vue').default})
