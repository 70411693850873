
import OfferCard from '@/components/offer/OfferCard'
import { API } from '@/services/api.service'
import { mapActions } from 'vuex'

export default {
  name: 'HomeOffers',
  components: { OfferCard },
  data () {
    return {
      offers: null,
      frontPageOffers: false
    }
  },
  computed: {
    getTitle () {
      return this.frontPageOffers ? 'Les annonces à la une' : this.$t('pages.home.lastOffers.title')
    }
  },
  async fetch () {
    await this.fetchOffers()
  },
  methods: {
    ...mapActions({
      gtmClick: 'gtm/clickItem'
    }),
    async fetchOffers () {
      try {
        const { data } = await API.get('/offers/front-page?limit=9')
        if (data && data.offers && data.offers.length > 0) {
          this.offers = data.offers
          this.frontPageOffers = true
        } else {
          const { data: data2 } = await API.get('/offers?limit=9')
          if (data2 && data2.offers)
            this.offers = data2.offers
        }
      } catch (e) {
        this.offers = undefined
      }
    },
    async gtmImpression (isVisible, offer, index) {
      if (!isVisible) return
      await this.$store.dispatch('gtm/viewItemList', {
        item: offer,
        listId: 'home',
        list: 'Home',
        index,
      })
    }
  }
}
