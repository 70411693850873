
import { mapGetters } from 'vuex'
import ArmEnum from '@/enum/ArmEnum'
import HandEnum from '@/enum/HandEnum'
import Currency from '@/enum/currency'
import { roundPrice } from '@/utils'
import capitalize from '@/utils/capitalize'

export default {
  name: 'OfferCard',

  props: {
    offer: Object
  },

  data () {
    return {
      ARM: ArmEnum,
      HandEnum,
      getNoImgAvailable: '/assets/img/no_image_available.jpg'
    }
  },

  computed: {
    ...mapGetters({
      getCategory: 'layout/getCategory',
    }),
    currencySymbol () {
      if (!this.offer.currency) return '?'
      return Currency[this.offer.currency].symbol
    },
    displayedBrand () {
      return this.offer.fullBrand ? this.offer.fullBrand.name : this.offer.brand
    },
    displayedPrice () {
      const { price } = this.offer
      return price > 0 ? roundPrice(price) : 0
    },
    displayedAddress () {
      const { address } = this.offer
      if (address === undefined || address === null || address.city == null) return 'Non précisée'
      const { city, addressCountry } = this.offer.address
      return `${city} (${addressCountry?.isoCode})`
    },
    getFirstImgUrl () {
      if (this.offer.img.length < 1) return ''
      return this.offer.img[0].url
    },
    isFrontPage () {
      return this.offer && this.offer.options && this.offer.options.map(o => o.type).includes('front_page')
    },
    isFrontPageClass () {
      if (this.isFrontPage) {
        return '__front'
      }
      return ''
    }
  },

  methods: {
    displayedArm (arm) {
      return capitalize(this.$t(ArmEnum[arm].value))
    }
  }
}
