module.exports = {
  // NORMAL
  DESKTOP_HOME: {
    name: 'desktop_home',
    width: 336,
    height: 280
  },
  DESKTOP_OFFERS: {
    name: 'desktop_offers',
    width: 728,
    height: 90
  },
  DESKTOP_OFFERS_BOTTOM: {
    name: 'desktop_offers_bottom',
    width: 728,
    height: 90
  },
  DESKTOP_OFFER: {
    name: 'desktop_offer',
    width: 160,
    height: 600
  },
  DESKTOP_PROFILE: {
    name: 'desktop_profile',
    width: 160,
    height: 600
  },

  // MOBILE
  MOBILE_HOME: {
    name: 'mobile_home',
    width: 300,
    height: 250
  },
  MOBILE_OFFERS: {
    name: 'mobile_offers',
    width: 728,
    height: 90
  },
  MOBILE_OFFERS_BOTTOM: {
    name: 'mobile_offers_bottom',
    width: 728,
    height: 90
  },
  MOBILE_OFFER: {
    name: 'mobile_offer',
    width: 728,
    height: 90
  },
  MOBILE_PROFILE: {
    name: 'mobile_profile',
    width: 728,
    height: 90
  },
}
