
import { mapGetters } from 'vuex'

export default {
  name: 'HomeBrands',

  methods: {
    brandPath (brand) {
      return this.localePath({ name: 'brands-slug', params: { slug: brand.slug } })
    }
  },

  computed: {
    ...mapGetters({
      brands: 'layout/getBrandsOrdered',
    })
  }
}
