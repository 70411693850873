
import Banner from '@/components/banner/Banner'
import RouteService from '@/services/route.service'

export default {
  name: 'BannerView',
  components: { Banner },
  props: {
    type: Object
  },
  data () {
    return {
      banners: [],
      isLoaded: false,
      isLoading: true,
      intervalId: null,
      index: 0
    }
  },
  computed: {
    currentBanner () {
      return this.banners ?
        this.banners[this.index] : null
    }
  },
  async mounted () {
    if (this.intervalId === null && !this.isLoaded) {
      await this.fetchBanners()
      if (this.isLoaded)
        this.initLoop()
    }
  },
  destroyed () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  methods: {
    async fetchBanners () {
      this.isLoaded = false
      try {
        const { data } = await RouteService.getBanners(this.type.name)
        this.banners = data.banners
        this.isLoaded = true
      } catch (e) {
        // TODO
      }
    },
    initLoop () {
      if (this.banners.length <= 1) return
      this.intervalId = setInterval(() => {
        if (this.index + 1 >= this.banners.length)
          this.index = 0
        else
          this.index++
      }, this.$config.adsDelay)
    }
  }
}
