
import debounce from 'lodash/debounce'

export default {
  name: 'Banner',
  props: {
    banner: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      imgIsLoaded: false,
      tracked: [],
      offsetTop: 0
    }
  },

  computed: {
    alreadyTracked () {
      return this.tracked.includes(this.banner.id)
    }
  },

  watch: {
    'banner' () {
      this.imgIsLoaded = false
      this.$Lazyload.$once('loaded', this.handleView)
    },
    offsetTop: debounce(function () {
      this.handleView()
    }, 400)
  },

  async mounted () {
    this.imgIsLoaded = false
    this.$Lazyload.$once('loaded', this.handleView)
  },

  created () {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    this.tracked = []
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    handleView () {
      if (!process.client) return
      this.imgIsLoaded = true
      if (!this.alreadyTracked && this.isElementInViewport(this.$refs.sda)) {
        this.$gtm.push({
          event: 'viewSda',
          sdaId: this.banner.id,
          sdaType: this.banner.type
        })
        this.tracked.push(this.banner.id)
      }
    },
    handleClick () {
      this.$gtm.push({
        event: 'clickSda',
        sdaId: this.banner.id,
        sdaType: this.banner.type
      })
    },
    onScroll () {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
    },
    isElementInViewport (el, percentVisible = 100) {
      if (!el) return false
      const
        rect = el.getBoundingClientRect(),
        windowHeight = (window.innerHeight || document.documentElement.clientHeight)
      return !(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height)) * 100)) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
      )
    }
  }
}
